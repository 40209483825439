import React from 'react'
import electronic from '../assets/electronic.mp4'
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'
import { Link } from 'react-router-dom'
import groupWebsites from '../assets/services/6.mp4'
import product1 from '../assets/electronicProducts/1.png'
import product2 from '../assets/electronicProducts/22.png'
import product3 from '../assets/electronicProducts/3.png'

const Electronic = () => {
  document.addEventListener("DOMContentLoaded", function() {
    // Get the video element
    var video = document.querySelector('.electronicTag');
  
    // Play the video
    video.play();
  });
  
  return (
    <div className='container-fluid'>
        <section className="electronics-background">
            <video className='electronicTag' autoPlay loop muted playsInline>
            <source src={electronic} type='video/mp4' />
            Your browser does not support the video tag.
            </video>
            <div className="overlay">
              <div className="text-overlay frame-1">
                <p className="text-uppercase title text-light"><b>Welcome To <span style={{ color: "yellow" }}>Zaxix Electronics</span></b></p>
                <p className="sub-title text-light">Where Innovation Meets Connectivity!</p>
              </div>
            </div>
        </section>

        <section className='aboutElectronics'>
          <div className="row">
            <div className="col-md-7" id="aboutElectronics1">
              <p style={{ fontSize: "25px", fontFamily: "-moz-initial", fontWeight: "bolder" }}>Zaxix Electronics is your trusted partner in the world of cutting-edge technology. We specialize in providing innovative electronic solutions that enhance everyday life, drive efficiency, and inspire creativity. With a commitment to quality, reliability, and customer satisfaction, we strive to be your go-to source for all things electronic. At Zaxix Electronics, we envision a future where technology enriches every aspect of human existence, making life more convenient, enjoyable, and sustainable. Our goal is to play a pivotal role in shaping this future by offering innovative electronic products and services that empower individuals, businesses, and communities to thrive in the digital age.</p>
            </div>
            <div className="col-md-5" id='aboutElectronics2'>
              <div className="row">
                <div className="col-md-6 my-4">
                    <i className="bi bi-lightbulb-fill" style={{ fontSize: '4rem' }}></i>
                    <h5>Consumer Electronics</h5>
                </div>
                <div className="col-md-6 my-4">
                    <i className="bi bi-house-gear-fill" style={{ fontSize: '4rem' }}></i>
                    <h5>Smart Home Solutions</h5>
                </div>
                <div className="col-md-6 my-4">
                    <i className="bi bi-globe" style={{ fontSize: '4rem' }}></i>
                    <h5>Business Solutions</h5>
                </div>
                <div className="col-md-6 my-4">
                    <i className="bi bi-tools" style={{ fontSize: '4rem' }}></i>
                    <h5>Technical Support</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className='aboutElectronics' id='aboutElectronic'>
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>ABOUT ZAXIX ELECTRONICS</b></h2>
            <div className="text-muted my-4">
              Zaxix Electronics is your trusted partner in the world of cutting-edge technology. We specialize in providing innovative electronic solutions that enhance everyday life, drive efficiency, and inspire creativity. With a commitment to quality, reliability, and customer satisfaction, we strive to be your go-to source for all things electronic. At Zaxix Electronics, we envision a future where technology enriches every aspect of human existence, making life more convenient, enjoyable, and sustainable. Our goal is to play a pivotal role in shaping this future by offering innovative electronic products and services that empower individuals, businesses, and communities to thrive in the digital age.
            </div>
        </section> */}

        {/* <section className='electronicServices' id='electronicService'>
          <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SERVICES</b></h2>
          <div className="row">
            <div className="col-md-3 my-4">
                <i className="bi bi-lightbulb-fill" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Consumer Electronics</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-house-gear-fill" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Smart Home Solutions</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-globe" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Business Solutions</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-tools" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Technical Support</h5>
            </div>
          </div>
        </section> */}

        {/* <section className="chooseElectronic">
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Why Choose US?</b></h2>
          <div className="accordion my-4" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Innovation
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  We are constantly pushing the boundaries of innovation to bring you the latest advancements in electronic technology. From cutting-edge gadgets to smart home solutions, we're always on the forefront of what's next.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Quality Assurance
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  We take pride in the quality and reliability of our products. Each product undergoes rigorous testing to ensure it meets our high standards for performance, durability, and safety.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Customer-Centric Approach
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  Your satisfaction is our top priority. Whether you're a consumer, a business, or an organization, we are committed to providing you with personalized service and support to meet your specific needs and requirements.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Sustainability
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  We believe in the responsible use of technology to minimize our environmental impact. That's why we strive to offer energy-efficient products, reduce waste in our manufacturing processes, and promote recycling and eco-friendly practices.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Expertise
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  With a team of experienced professionals and industry experts, we have the knowledge and skills to help you navigate the ever-evolving world of electronics. Whether you need advice on product selection, installation assistance, or technical support, we're here to help.
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="electronicProduct">
        <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Our Products</b></h2>
          <div className="container" align="center">
          <div className="row">
            <div className="col-md-4">
              <Link to="/electronicProject"><div className="card mx-2 my-2" style={{ width: "18rem" }}>
                <img src={product1} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title text-muted"><b>DC Mobile Chargers & Power Supply</b></h5>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4">
              <Link to="/electronicProject"><div className="card mx-2 my-2" style={{ width: "18rem" }}>
                <img src={product2} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title text-muted"><b>Indoor Lightings</b></h5>
                </div>
              </div></Link>
            </div>
            <div className="col-md-4">
              <Link to="/electronicProject"><div className="card mx-2 my-2" style={{ width: "18rem" }}>
                <img src={product3} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title text-muted"><b>Outdoor Lightings</b></h5>
                </div>
              </div></Link>
            </div>
          </div>
          </div>
        </section>

      <section className="ourGroupWebsites" style={{ position: "relative" }}>
        <video autoPlay playsInline controls={false} loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "1" }}>
            <source src={groupWebsites} type="video/mp4" />
            {/* Add more <source> elements for other video formats if needed */}
            Your browser does not support the video tag.
        </video>
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR OTHER SEGMENTS</b></h2>
        <div className="container">
          <div className="row">
            <div className="mx-4 my-4 text-muted" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <div className="service-item">
                <Link to="/electronics" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage1} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRONICS</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/solar" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                <img src={serviceImage2} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                <p style={{ textAlign: "center" }}><b>ZAXIX SOLAR</b></p>
              </div></Link>
              </div>
              <div className="service-item">
                <Link to="/wind" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage3} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX WIND</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/electrical" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage4} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRICAL CAPEX</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/technologies" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage5} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX TECHNOLOGIES</b></p>
                </div></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Electronic