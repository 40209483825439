import React from 'react'
import wind from '../assets/wind.mp4'
import service1 from '../assets/windServices/1.jpg'
import service2 from '../assets/windServices/2.jpg'
import service3 from '../assets/windServices/3.jpg'
import service4 from '../assets/windServices/4.jpg'
import { Link } from 'react-router-dom'
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'
import groupWebsites from '../assets/services/6.mp4'

const Wind = () => {
  document.addEventListener("DOMContentLoaded", function() {
    // Get the video element
    var video = document.querySelector('.videoTag');
  
    // Play the video
    video.play();
  });

  return (
    <div className='container-fluid'>
        <section className="wind-background">
            <video className='windTag' autoPlay loop muted playsInline>
            <source src={wind} type='video/mp4' />
            Your browser does not support the video tag.
            </video>
            <div className="overlay">
              <div className="text-overlay frame-1">
                <p className="text-uppercase title text-light"><b>Welcome To <span style={{ color: "yellow" }}>Zaxix Wind</span></b></p>
                <p className="sub-title text-light">Empowering the Future, One Gust at a Time!</p>
              </div>
            </div>
        </section>

        <section className='aboutWind' id='aboutWinds'>
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>ABOUT ZAXIX WIND</b></h2>
            <div className="text-muted">
              At Zaxix Wind, we are committed to harnessing the power of the wind to drive positive change in the world. Our mission is to provide innovative wind energy solutions that promote sustainability, reduce carbon emissions, and pave the way for a brighter future. We envision a world where renewable energy sources like wind power play a central role in meeting our growing energy needs. At Zaxix Wind, we strive to make this vision a reality by offering cutting-edge wind energy solutions that empower individuals, businesses, and communities to embrace clean, sustainable living.
            </div>
        </section>

        <section className='windServices' id='windService'>
          <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SERVICES</b></h2>
          <div className="row">
            <div className="col-md-3 my-4">
                <i className="bi bi-house-door" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Residential Wind</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-building-gear" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Commercial Wind</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-buildings" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Community Wind</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-bricks" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Wind Farm Development</h5>
            </div>
          </div>
        </section>

        <section className="chooseWind">
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Why Choose US?</b></h2>
          <div className="accordion my-4" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Expertise
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  With years of experience and expertise in the wind energy industry, our team of professionals is dedicated to delivering high-quality wind energy solutions that exceed expectations.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Reliable Technology
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  We utilize state-of-the-art wind turbines and components from leading manufacturers to ensure the reliability and performance of our wind energy systems.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Customized Solutions
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  Whether you're looking to power your home, business, or community with wind energy, we offer customized solutions tailored to your specific needs and requirements.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Environmental Impact
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  By choosing Zaxix Wind, you're not only investing in clean, renewable energy; you're also contributing to the fight against climate change and environmental degradation.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Customer Satisfaction
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  At Zaxix Wind, customer satisfaction is paramount. From initial consultation to installation and ongoing support, we are committed to providing exceptional service and support every step of the way.
                </div>
              </div>
            </div>
          </div>
        </section>

      <section className="ourGroupWebsites" style={{ position: "relative" }}>
        <video autoPlay playsInline controls={false} loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "1" }}>
            <source src={groupWebsites} type="video/mp4" />
            {/* Add more <source> elements for other video formats if needed */}
            Your browser does not support the video tag.
        </video>
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR OTHER SEGMENTS</b></h2>
        <div className="container">
          <div className="row">
            <div className="mx-4 my-4 text-muted" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <div className="service-item">
                <Link to="/electronics" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage1} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRONICS</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/solar" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                <img src={serviceImage2} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                <p style={{ textAlign: "center" }}><b>ZAXIX SOLAR</b></p>
              </div></Link>
              </div>
              <div className="service-item">
                <Link to="/wind" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage3} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX WIND</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/electrical" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage4} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRICAL CAPEX</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/technologies" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage5} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX TECHNOLOGIES</b></p>
                </div></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Wind