import React, { useState } from 'react'
// import ZAxix from '../assets/favicon-32x32.png'
import ZAxix from '../assets/android-chrome-192x192.png'
import ZAxixWhite from '../assets/white-192x192.png'
import certificate1 from '../assets/registration/1.jpeg'
import certificate2 from '../assets/registration/2.jpeg'
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [scrollText, setScrollText] = useState('');

    function changeBg() {
        var navbar = document.getElementById('navbar');
        var scrollValue = window.scrollY;
        if(scrollValue < 100){
            navbar.classList.remove('bgColor')
            navbar.classList.add('navbar-dark')
            setScrollText('');
        }else {
            navbar.classList.add('bgColor')
            navbar.classList.remove('navbar-dark')
            const pathname = window.location.pathname;
            if(pathname === '/electronics' || pathname === '/electronicProject') {
                setScrollText(
                    <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}>
                        <b className="mx-2 mobileLogo">ZAXIX ELECTRONICS</b>
                    </div>
                );
            }else if(pathname === '/solar' || pathname === '/solarProject') {
                setScrollText(
                    <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}>
                        <b className="mx-2 mobileLogo">ZAXIX SOLAR</b>
                    </div>
                );
            }else if(pathname === '/wind' || pathname === '/windProject') {
                setScrollText(
                    <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}>
                        <b className="mx-2 mobileLogo">ZAXIX WIND</b>
                    </div>
                );
            }else if(pathname === '/electrical' || pathname === '/electricalProject') {
                setScrollText(
                    <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}>
                        <b className="mx-2 mobileLogo">ZAXIX ELECTRICAL CAPEX</b>
                    </div>
                );
            }else if(pathname === '/technologies' || pathname === '/technologiesProject') {
                setScrollText(
                    <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}>
                        <b className="mx-2 mobileLogo">ZAXIX TECHNOLOGIES</b>
                    </div>
                );
            }else {
                setScrollText(
                    <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}>
                        <b className="mx-2 mobileLogo">ZAXIX GROUP</b>
                    </div>
                );
            }
        }
    }

    const renderHeaderOptions = () => {
        const pathname = window.location.pathname;
        if(pathname === '/electronics' || pathname === '/electronicProject') {
            return(
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                    <Link className="nav-link active" to="/" style={{ fontSize: '17px' }}><b>Home</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/electronicProject" style={{ fontSize: '17px' }}><b>Our Products</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" style={{ fontSize: '17px' }}><b>Research & Development</b></Link>
                    </li>
            </ul>
            )
        }else if(pathname === '/solar' || pathname === '/solarProject') {
            return(
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                    <Link className="nav-link active" to="/" style={{ fontSize: '17px' }}><b>Home</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/solarProject" style={{ fontSize: '17px' }}><b>Our Projects</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" style={{ fontSize: '17px' }}><b>Research & Development</b></Link>
                    </li>
                </ul>
            )
        }else if(pathname === '/wind' || pathname === '/windProject') {
            return(
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                    <Link className="nav-link active" to="/" style={{ fontSize: '17px' }}><b>Home</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/windProject" style={{ fontSize: '17px' }}><b>Our Projects</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" style={{ fontSize: '17px' }}><b>Research & Development</b></Link>
                    </li>
                </ul>
            )
        }else if(pathname === '/electrical' || pathname === '/electricalProject') {
            return(
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                    <Link className="nav-link active" to="/" style={{ fontSize: '17px' }}><b>Home</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/electricalProject" style={{ fontSize: '17px' }}><b>Our Projects</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" style={{ fontSize: '17px' }}><b>Research & Development</b></Link>
                    </li>
                </ul>
            )
        }else if(pathname === '/technologies' || pathname === '/technologiesProject') {
            return(
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                    <Link className="nav-link active" to="/" style={{ fontSize: '17px' }}><b>Home</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/technologiesProject" style={{ fontSize: '17px' }}><b>Our Projects</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" style={{ fontSize: '17px' }}><b>Research & Development</b></Link>
                    </li>
                </ul>
            )
        }else {
            return(
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                    <Link className="nav-link active" to="/about" style={{ fontSize: '17px' }}><b>About Us</b></Link>
                    </li>
                    <li className="nav-item dropdown">
                    <Link className="nav-link active dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" style={{ fontSize: '17px' }}>
                        <b>Our Business</b>
                    </Link>
                    <ul className="dropdown-menu" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <li><Link className="dropdown-item" to="/electronics"><b>Zaxix Electronics</b></Link></li>
                        <li><Link className="dropdown-item" to="/solar"><b>Zaxix Solar</b></Link></li>
                        <li><Link className="dropdown-item" to="/wind"><b>Zaxix Wind</b></Link></li>
                        <li><Link className="dropdown-item" to="/electrical"><b>Zaxix Electrical Capex</b></Link></li>
                        <li><Link className="dropdown-item" to="/technologies"><b>Zaxix Technologies</b></Link></li>
                    </ul>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/sustainability" style={{ fontSize: '17px' }}><b>Sustainability</b></Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" style={{ fontSize: '17px' }}><b>Research & Development</b></Link>
                    </li>
                </ul>
            )
        }
    }

    window.addEventListener('scroll', changeBg);
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark sticky-top" id='navbar' style={{boxShadow: window.scrollY > 100 ? "0px 2px 4px rgba(0, 0, 0, 0.4)" : ""}}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img src={window.scrollY > 100 ? ZAxix : ZAxixWhite} alt="..." style={{ height: "8vh", width: "8vh" }} />{scrollText}</Link>
                {window.scrollY > 100 ? 
                <Link className="navbar-brand" to="/"><img src={certificate1} className='mobileCertificate' alt="..." /></Link>
                : ""}
                {window.scrollY > 100 ? 
                <Link className="navbar-brand" to="/"><img src={certificate2} className='mobileCertificate' alt="..." /></Link>
                : ""}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {renderHeaderOptions()}
                </div>
            </div>
        </nav>
    </div>
  )
}

export default Navbar