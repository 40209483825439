import React from 'react'
import solar from '../assets/solar.mp4'
import { Link } from 'react-router-dom'
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'
import groupWebsites from '../assets/services/6.mp4'
import Project1 from '../assets/solarProjects/1/1.jpg'
import Project2 from '../assets/solarProjects/1/2.jpg'
import Project3 from '../assets/solarProjects/1/3.jpg'
import Project4 from '../assets/solarProjects/1/4.jpg'
import Project5 from '../assets/solarProjects/1/5.jpg'

const Solar = () => {
  document.addEventListener("DOMContentLoaded", function() {
    // Get the video element
    var video = document.querySelector('.solarTag');
  
    // Play the video
    video.play();
  });

  return (
    <div className='container-fluid'>
        <section className="solar-background">
            <video className='solarTag' autoPlay loop muted playsInline>
            <source src={solar} type='video/mp4' />
            Your browser does not support the video tag.
            </video>
            <div className="overlay">
              <div className="text-overlay frame-1">
                <p className="text-uppercase title text-light"><b>Welcome To <span style={{ color: "yellow" }}>Zaxix Solar</span></b></p>
                <p className="sub-title text-light">Powering Progress, Shining Bright!</p>
              </div>
            </div>
        </section>

        <section className='aboutSolar' id='aboutSolars'>
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>ABOUT ZAXIX SOLAR</b></h2>
            <div className="text-muted">
              At Zaxix Solar, we are dedicated to revolutionizing the way the world harnesses renewable energy. Our mission is to provide cutting-edge solar solutions that empower individuals and communities to embrace sustainable living while reducing their carbon footprint. We envision a future where clean, renewable energy is readily accessible to all, where solar power becomes the cornerstone of a sustainable energy ecosystem. With Zaxix Solar, we aim to make this vision a reality by offering innovative solar products and services tailored to meet the diverse needs of our customers.
            </div>
        </section>

        <section className='solarServices' id='solarService'>
          <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SERVICES</b></h2>
          <div className="row">
            <div className="col-md-3 my-4">
                <i className="bi bi-house-door-fill" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Residential Solar</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-building-fill" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Commercial Solar</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-buildings-fill" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Industrial Solar</h5>
            </div>
            <div className="col-md-3 my-4">
                <i className="bi bi-tools" style={{ fontSize: '4rem' }}></i>
                <h5 className='text-muted'>Solar Maintenance & Monitoring</h5>
            </div>
          </div>
        </section>

        <section className="chooseSolar">
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Our Top Projects</b></h2>
          <div className="row justify-content-center mx-2 my-4">
            <div className="col-md-4 d-flex justify-content-center">
              <div id="carouselExampleAutoplaying" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Project1} className="d-block w-99 solarProjectMobile" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={Project4} className="d-block w-99 solarProjectMobile" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={Project2} className="d-block w-99 solarProjectMobile" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={Project3} className="d-block w-99 solarProjectMobile" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src={Project5} className="d-block w-99 solarProjectMobile" alt="..." />
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </section>

      <section className="ourGroupWebsites" style={{ position: "relative" }}>
        <video autoPlay playsInline controls={false} loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "1" }}>
            <source src={groupWebsites} type="video/mp4" />
            {/* Add more <source> elements for other video formats if needed */}
            Your browser does not support the video tag.
        </video>
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR OTHER SEGMENTS</b></h2>
        <div className="container">
          <div className="row">
            <div className="mx-4 my-4 text-muted" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <div className="service-item">
                <Link to="/electronics" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage1} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRONICS</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/solar" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                <img src={serviceImage2} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                <p style={{ textAlign: "center" }}><b>ZAXIX SOLAR</b></p>
              </div></Link>
              </div>
              <div className="service-item">
                <Link to="/wind" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage3} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX WIND</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/electrical" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage4} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRICAL CAPEX</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/technologies" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage5} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX TECHNOLOGIES</b></p>
                </div></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Solar