import React from 'react'
import product1 from '../assets/electronicProducts/1.png'
import product2 from '../assets/electronicProducts/2.png'
import product3 from '../assets/electronicProducts/3.png'
import product4 from '../assets/electronicProducts/4.png'
import product5 from '../assets/electronicProducts/5.png'
import product7 from '../assets/electronicProducts/7.png'
import product8 from '../assets/electronicProducts/8.png'
import product9 from '../assets/electronicProducts/9.png'
import product10 from '../assets/electronicProducts/10.png'
import product11 from '../assets/electronicProducts/11.png'
import product12 from '../assets/electronicProducts/12.png'
import product13 from '../assets/electronicProducts/13.png'
import product14 from '../assets/electronicProducts/14.png'
import product15 from '../assets/electronicProducts/15.png'
import product16 from '../assets/electronicProducts/16.png'
import product17 from '../assets/electronicProducts/17.png'
import product18 from '../assets/electronicProducts/18.png'
import product19 from '../assets/electronicProducts/19.png'
import product20 from '../assets/electronicProducts/20.png'
import product21 from '../assets/electronicProducts/21.png'
import product22 from '../assets/electronicProducts/22.png'
import product23 from '../assets/electronicProducts/23.png'
import product24 from '../assets/electronicProducts/24.png'
import product25 from '../assets/electronicProducts/25.png'
import product26 from '../assets/electronicProducts/26.png'
import product27 from '../assets/electronicProducts/27.png'
import product28 from '../assets/electronicProducts/28.png'

const ElectronicProject = () => {
  return (
    <div>
        <section className="electronicProject1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>Product Gallery</strong></h1>
        </section>

        <section className="electronicProject2">
        <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Our Products</b></h2>
          <div className="container">
            <div className="row">
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Chargers</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product3} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Street Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product10} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Designer Solar Gate/Wall Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product13} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Wall Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product15} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Rechargeable Wardrobe Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product16} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>COB Garden Spike Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product24} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Panel Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product26} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Lamp</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product4} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Flood Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product2} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>DC Power Supplies</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product5} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Stadium/Tower Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product8} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Solar Semi-Integrated Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product9} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Solar Integrated Street Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product11} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Solar Wall Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product12} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Wall/Step Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product14} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Step Lights</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product17} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Cob Track Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product18} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Cob Focus Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product19} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Linear Spot Downlight</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product20} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Cob Deep</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product21} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Cob Hanging/Long Surface</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product22} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Cob Surface</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product23} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>Cob Zoom</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product25} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Panel Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product27} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>BLDC Ceiling Fan</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product7} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>LED Solar Street Light</b></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2">
                <div className="card cardHover" style={{ width: "18rem" }}>
                  <img src={product28} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase"><b>BLDC Ventilation Fan</b></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default ElectronicProject