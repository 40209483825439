import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Solar from './components/Solar';
import Electronic from './components/Electronic';
import Wind from './components/Wind';
import Electrical from './components/Electrical';
import Careers from './components/Careers';
import Technologies from './components/Technologies';
import About from './components/About';
import Sustainability from './components/Sustainability';
import ScrollToTop from './components/ScrollToTop';
import ElectronicProject from './components/ElectronicProject';
import SolarProject from './components/SolarProject';
import WindProject from './components/WindProject';
import ElectricalProject from './components/ElectricalProject';
import TechnologiesProject from './components/TechnologiesProject';

function App() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
      <Navbar />
          <div className="App">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/solar" element={<Solar />} />
              <Route exact path="/electronics" element={<Electronic />} />
              <Route exact path="/wind" element={<Wind />} />
              <Route exact path="/electrical" element={<Electrical />} />
              <Route exact path="/careers" element={<Careers />} />
              <Route exact path="/technologies" element={<Technologies />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/sustainability" element={<Sustainability />} />
              <Route exact path="/electronicProject" element={<ElectronicProject />} />
              <Route exact path="/solarProject" element={<SolarProject />} />
              <Route exact path="/windProject" element={<WindProject />} />
              <Route exact path="/electricalProject" element={<ElectricalProject />} />
              <Route exact path="/technologiesProject" element={<TechnologiesProject />} />
            </Routes>
          </div>
      <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
