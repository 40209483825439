import React from 'react'
import { Link } from 'react-router-dom'
import Project1 from '../assets/technologiesProjects/1.png'
import Project2 from '../assets/technologiesProjects/2.png'
import Project3 from '../assets/technologiesProjects/3.png'
import Project4 from '../assets/technologiesProjects/4.png'

const TechnologiesProject = () => {
  return (
    <div>
        <section className="technologiesProject1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>Project Gallery</strong></h1>
        </section>

        <section className="technologiesProject2">
        <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b><u>Our Highlights</u></b></h2>
        <div>
          <div className="row justify-content-center mx-2 my-4">
            <div className="col-md-4 d-flex justify-content-center">
              <div className="card" style={{ width: "18rem" }}>
                <img src={Project1} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Blue Earth Groups</h5>
                  <Link to="https://blueearthgroups.com/" className="btn btn-primary" target='_blank'>Visit Site</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div className="card" style={{ width: "18rem" }}>
                <img src={Project2} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Golden Arm</h5>
                  <Link to="https://goldenarm.co.in/" className="btn btn-primary" target='_blank'>Visit Site</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div className="card" style={{ width: "18rem" }}>
                <img src={Project3} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">E-commerce</h5>
                  <Link to="https://ecommerce-he5h.onrender.com/" className="btn btn-primary" target='_blank'>Visit Site</Link>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mx-2 my-4">
              <div className="col-md-4 d-flex justify-content-center">
                <div className="card" style={{ width: "18rem" }}>
                  <img src={Project4} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">Wordly World</h5>
                    <Link to="https://wordlyworld.netlify.app/" className="btn btn-primary" target='_blank'>Visit Site</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
    </div>
  )
}

export default TechnologiesProject