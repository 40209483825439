import React from 'react'
import project1 from '../assets/electricalProjects/1/1.jpg'
import project2 from '../assets/electricalProjects/1/2.jpg'
import project3 from '../assets/electricalProjects/1/3.jpg'
import project4 from '../assets/electricalProjects/1/4.jpg'
import project5 from '../assets/electricalProjects/1/5.jpg'
import project6 from '../assets/electricalProjects/1/6.jpg'
import project7 from '../assets/electricalProjects/1/7.jpg'
import project8 from '../assets/electricalProjects/1/8.jpg'
import project9 from '../assets/electricalProjects/1/9.jpg'
import project10 from '../assets/electricalProjects/1/10.jpg'
import project11 from '../assets/electricalProjects/1/11.jpg'
import project12 from '../assets/electricalProjects/1/12.jpg'
import project13 from '../assets/electricalProjects/1/13.jpg'
import project14 from '../assets/electricalProjects/1/14.jpg'
import project15 from '../assets/electricalProjects/1/15.jpg'
import project16 from '../assets/electricalProjects/1/16.jpg'
import project17 from '../assets/electricalProjects/1/17.jpg'
import project18 from '../assets/electricalProjects/1/18.jpg'
import project19 from '../assets/electricalProjects/1/19.jpg'
import project20 from '../assets/electricalProjects/1/20.jpg'
import project21 from '../assets/electricalProjects/2/1.jpg'
import project22 from '../assets/electricalProjects/2/2.jpg'
import project23 from '../assets/electricalProjects/2/3.jpg'
import project24 from '../assets/electricalProjects/2/4.jpg'
import project25 from '../assets/electricalProjects/3/1.jpg'
import project26 from '../assets/electricalProjects/3/2.jpg'
import project27 from '../assets/electricalProjects/4/1.jpg'
import project28 from '../assets/electricalProjects/4/2.jpg'

const ElectricalProject = () => {
  return (
    <div>
        <section className="electricalProject1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>Project Gallery</strong></h1>
        </section>

        <section className="electronicProject2">
        <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b><u>Our Highlights</u></b></h2>
        <div>
          <div className="row">
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project1} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project2} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project3} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Sammati (Nagpur, M.H.)</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying1" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project4} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project5} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project6} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Hind (Rajnandgaon, C.G.)</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying2" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project7} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project8} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Nidhivan (Gujarat)</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying3" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project9} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project10} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project11} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Magpie (Bhilai, C.G.)</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying4" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project12} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project13} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project14} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying4" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying4" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Indra</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying5" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project15} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project16} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project17} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying5" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying5" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Dhanush</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying6" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project18} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project19} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project20} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying6" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying6" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Amrit</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying7" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project21} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project22} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project23} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project24} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying7" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying7" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Neelsaar</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying8" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project25} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project26} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying8" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying8" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Ganga</b></h4>
            <hr />
            </div>
            <div className="col-md-6">
            <div id="carouselExampleAutoplaying9" className="carousel carousel-dark slide" data-bs-ride="carousel" align="center">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={project27} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={project28} className="d-block w-99" style={{ maxHeight: "70vh" }} alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying9" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying9" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <h4 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>Project Ashoka</b></h4>
            <hr />
            </div>
          </div>
        </div>
        </section>
    </div>
  )
}

export default ElectricalProject