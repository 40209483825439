import React from 'react'
import sustainabilityImage1 from '../assets/sustainability/4.jpg'
import sustainabilityImage2 from '../assets/sustainability/5.jpg'
import sustainabilityImage3 from '../assets/sustainability/1.jpg'
import sustainabilityImage4 from '../assets/sustainability/6.jpg'
import sustainabilityImage5 from '../assets/sustainability/7.jpg'

const Sustainability = () => {
  return (
    <div>
        <section className="sustainabilityPart1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>Sustainability</strong></h1>
        </section>

        <section className="sustainabilityPart2">
            <div className="container-fluid">
                <p className='text-muted'>At <b>Zaxix Group</b>, we are deeply committed to sustainability and environmental stewardship. We recognize that our actions today have a lasting impact on the planet and future generations, which is why we prioritize sustainability in everything we do. From renewable energy solutions to eco-friendly business practices, we are dedicated to minimizing our environmental footprint and contributing to a more sustainable future.</p>
            </div>
        </section>

        <section className="sustainabilityPart3 container">
            {/* <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b></b></h2> */}
            <div className="sustainabilityPart3_1">
                <h5 className='text-dark my-4'><b>Renewable Energy Leadership</b></h5>
                <div className="row my-4">
                <div className="col-md-6">
                    <img src={sustainabilityImage1} alt="" className='sustainabilityPart3Image' />
                </div>
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px", marginTop: "7vh"}}>
                As a leader in renewable energy solutions, <b>Zaxix Group</b> is at the forefront of the transition towards a cleaner, greener energy future. Through our subsidiaries <b>Zaxix Solar</b> and <b>Zaxix Wind</b>, we are driving the adoption of renewable energy sources such as solar and wind power, helping to reduce reliance on fossil fuels and mitigate climate change.
                </div>
                </div>
            </div>
            <div className="sustainabilityPart3_2">
                <h5 className='text-dark my-4'><b>Environmental Responsibility</b></h5>
                <div className="row my-4">
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px", marginTop: "7vh"}}>
                We understand the importance of environmental responsibility in today's world. That's why we are committed to minimizing our environmental impact across all aspects of our operations. From reducing energy consumption and waste generation to promoting recycling and sustainable sourcing practices, we strive to operate in a way that is both environmentally responsible and economically viable.
                </div>
                <div className="col-md-6">
                    <img src={sustainabilityImage2} alt="" className='sustainabilityPart3Image' />
                </div>
                </div>
            </div>
            <div className="sustainabilityPart3_3">
                <h5 className='text-dark my-4'><b>Eco-Friendly Practices</b></h5>
                <div className="row my-4">
                <div className="col-md-6">
                    <img src={sustainabilityImage3} alt="" className='sustainabilityPart3Image' />
                </div>
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px", marginTop: "7vh"}}>
                At <b>Zaxix Group</b>, sustainability is not just a goal; it's a way of life. We integrate eco-friendly practices into every aspect of our business, from product design and manufacturing to packaging and distribution. We prioritize the use of sustainable materials, minimize waste wherever possible, and actively seek out opportunities to reduce our carbon footprint.
                </div>
                </div>
            </div>
            <div className="sustainabilityPart3_4">
                <h5 className='text-dark my-4'><b>Community Engagement</b></h5>
                <div className="row my-4">
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px", marginTop: "7vh"}}>
                We believe that sustainability is a collective effort that requires collaboration and engagement from all stakeholders. That's why we actively engage with local communities, nonprofit organizations, and government agencies to support environmental initiatives, promote sustainability education, and give back to the communities in which we operate.
                    </div>
                <div className="col-md-6">
                    <img src={sustainabilityImage4} alt="" className='sustainabilityPart3Image' />
                </div>
                </div>
            </div>
            <div className="sustainabilityPart3_5">
                <h5 className='text-dark my-4'><b>Continuous Improvement</b></h5>
                <div className="row my-4">
                <div className="col-md-6">
                    <img src={sustainabilityImage5} alt="" className='sustainabilityPart3Image' />
                </div>
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px", marginTop: "7vh"}}>
                Sustainability is an ongoing journey, and we are committed to continuous improvement in our sustainability efforts. We regularly review our practices, set ambitious sustainability goals, and invest in research and development to identify new ways to reduce our environmental impact and promote sustainability throughout our organization.

                    </div>
                </div>
            </div>
        </section>

        <section className="sustainabilityPart4">
            <div className="container-fluid">
                <h2 className='text-muted my-4'><b>Join Us in Our Sustainability Journey.</b></h2>
                <p className='text-muted'>At <b>Zaxix Group</b>, sustainability is not just a core value, It's a fundamental part of who we are. We invite you to join us in our sustainability journey and be a part of creating a brighter, more sustainable future for generations to come. Together, we can make a meaningful difference in the world.</p>
            </div>
        </section>
    </div>
  )
}

export default Sustainability