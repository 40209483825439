import React from 'react'
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'

const About = () => {
  return (
    <div>
        <section className="aboutPart1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>About Us</strong></h1>
        </section>

        <section className="aboutValues">
            <div className="row">
                <div className="col-md-3">
                    <i className="bi bi-buildings-fill" style={{ fontSize: '4rem' }}></i>
                    <h2>5+</h2>
                    <p className='text-muted'>5 Years Of Experience</p>
                </div>
                <div className="col-md-3">
                    <i className="bi bi-people-fill" style={{ fontSize: '4rem' }}></i>
                    <h2>2500+</h2>
                    <p className='text-muted'>Happy Customers</p>
                </div>
                <div className="col-md-3">
                    <i className="bi bi-check-square-fill" style={{ fontSize: '4rem' }}></i>
                    <h2>10+</h2>
                    <p className='text-muted'>Project Done</p>
                </div>
                <div className="col-md-3">
                    <i className="bi bi-person-fill-check" style={{ fontSize: '4rem' }}></i>
                    <h2>100+</h2>
                    <p className='text-muted'>Expert Workers</p>
                </div>
            </div>
        </section>

        <section className="aboutPart2">
            <div className="container-fluid">
                <h2 className='text-muted my-4'><b>Welcome to Zaxix Group</b></h2>
                <p className='text-muted'><b>Zaxix Group</b> is a leading conglomerate dedicated to driving innovation, sustainability, and progress across multiple industries. Founded on the principles of excellence, integrity, and environmental stewardship, we have emerged as a trusted partner and a catalyst for positive change in the global marketplace. Established with a vision to revolutionize the way we harness energy and technology, <b>Zaxix Group</b> began its journey with a focus on renewable energy solutions. Over the years, we have expanded our portfolio to encompass a diverse range of businesses, each specializing in different segments of the energy and technology sectors. At <b>Zaxix Group</b>, our mission is to empower individuals, businesses, and communities with innovative solutions that enhance sustainability, improve quality of life, and drive economic growth. We are committed to leveraging our expertise, resources, and global reach to make a meaningful and lasting impact in the world.</p>
            </div>
        </section>

        <section className="aboutPart3 container">
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SEGMENTS</b></h2>
            <div className="aboutPart3_1">
                <div className="row my-4">
                <div className="col-md-6">
                    <img src={serviceImage1} alt="" className='aboutPart3Image' />
                </div>
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px"}}>
                <h5 className='text-dark mb-4'><b>Zaxix Electronics</b></h5>
                    At <b>Zaxix Electronics</b>, we specialize in designing and manufacturing cutting-edge electronic products and components for a wide range of industries. From consumer electronics and telecommunications devices to industrial automation systems and IoT solutions, our innovative products are engineered to deliver superior performance, reliability, and versatility in the digital age.
                </div>
                </div>
            </div>
            <div className="aboutPart3_2">
                <div className="row my-4">
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px"}}>
                <h5 className='text-dark mb-4'><b>Zaxix Solar</b></h5>
                Harnessing the power of the sun, <b>Zaxix Solar</b> is dedicated to providing innovative solar energy solutions for residential, commercial, and industrial applications. Our high-efficiency solar panels, advanced inverters, and comprehensive energy management systems enable our clients to maximize their energy production, reduce their carbon footprint, and achieve energy independence.
                </div>
                <div className="col-md-6">
                    <img src={serviceImage2} alt="" className='aboutPart3Image' />
                </div>
                </div>
            </div>
            <div className="aboutPart3_3">
                <div className="row my-4">
                <div className="col-md-6">
                    <img src={serviceImage3} alt="" className='aboutPart3Image' />
                </div>
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px"}}>
                <h5 className='text-dark mb-4'><b>Zaxix Wind</b></h5>
                <b>Zaxix Wind</b> is at the forefront of the renewable energy revolution, offering innovative wind energy solutions to power the future sustainably. With state-of-the-art wind turbines, advanced monitoring and control systems, and comprehensive wind farm development services, we are driving the transition to clean, renewable energy sources and paving the way for a greener tomorrow.
                    </div>
                </div>
            </div>
            <div className="aboutPart3_4">
                <div className="row my-4">
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px"}}>
                <h5 className='text-dark mb-4'><b>Zaxix Electrical Capex</b></h5>
                As a leader in electrical infrastructure solutions, <b>Zaxix Electrical Capex</b> offers a comprehensive range of products and services to meet the diverse needs of our clients. From power distribution and transmission equipment to renewable energy solutions and smart grid technologies, we provide tailored solutions that enable efficient, reliable, and sustainable electrical systems.
                    </div>
                <div className="col-md-6">
                    <img src={serviceImage4} alt="" className='aboutPart3Image' />
                </div>
                </div>
            </div>
            <div className="aboutPart3_5">
                <div className="row my-4">
                <div className="col-md-6">
                    <img src={serviceImage5} alt="" className='aboutPart3Image' />
                </div>
                <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px"}}>
                <h5 className='text-dark mb-4'><b>Zaxix Technologies</b></h5>
                <b>Zaxix Technologies</b> is a dynamic leader in the realm of digital innovation, dedicated to empowering businesses and individuals with transformative technology solutions. With a focus on creativity, reliability, and customer-centricity, we strive to be your trusted partner in navigating the complexities of the digital landscape and unlocking new opportunities for growth and success.
                    </div>
                </div>
            </div>
        </section>

        <section className="ourValues">
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR VALUES</b></h2>
            <div className="row">
                <div className="col-md-3">
                    <i className="bi bi-trophy-fill" style={{ fontSize: '4rem' }}></i>
                    <p className='text-muted'>Excellence</p>
                </div>
                <div className="col-md-3">
                    <i className="bi bi-diagram-2-fill" style={{ fontSize: '4rem' }}></i>
                    <p className='text-muted'>Integrity</p>
                </div>
                <div className="col-md-3">
                    <i className="bi bi-lightbulb-fill" style={{ fontSize: '4rem' }}></i>
                    <p className='text-muted'>Innovation</p>
                </div>
                <div className="col-md-3">
                    <i className="bi bi-recycle" style={{ fontSize: '4rem' }}></i>
                    <p className='text-muted'>Sustainability</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default About