import React from 'react'
import electrical from '../assets/electrical.mp4'
import { Link } from 'react-router-dom';
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'
import groupWebsites from '../assets/services/6.mp4'
import product1 from '../assets/electricalProducts/1.png'
import product2 from '../assets/electricalProducts/2.png'
import product3 from '../assets/electricalProducts/3.png'
import product4 from '../assets/electricalProducts/4.png'
import product5 from '../assets/electricalProducts/5.png'
import product6 from '../assets/electricalProducts/6.png'
import product7 from '../assets/electricalProducts/7.png'
import product8 from '../assets/electricalProducts/8.png'
import product9 from '../assets/electricalProducts/9.png'
import product10 from '../assets/electricalProducts/10.png'
import product13 from '../assets/electricalProducts/13.png'

const Electrical = () => {
  document.addEventListener("DOMContentLoaded", function() {
    // Get the video element
    var video = document.querySelector('.electricalTag');
  
    // Play the video
    video.play();
  });

  function scrollLeft() {
    const container = document.getElementById('scrollableRow');
    container.scrollLeft -= 100; // Adjust the scroll distance as needed
  }
  
  function scrollRight() {
    const container = document.getElementById('scrollableRow');
    container.scrollLeft += 100; // Adjust the scroll distance as needed
  }
  
  
  return (
    <div className='container-fluid'>
        <section className="electrical-background">
            <video className='electricalTag' autoPlay loop muted playsInline>
            <source src={electrical} type='video/mp4' />
            Your browser does not support the video tag.
            </video>
            <div className="overlay">
              <div className="text-overlay frame-1">
                <p className="text-uppercase title text-light"><b>Welcome To <span style={{ color: "yellow" }}>Zaxix Electrical Capex</span></b></p>
                <p className="sub-title text-light">Illuminating Innovation, Empowering Excellence!</p>
              </div>
            </div>
        </section>

        <section className='aboutElectrical'>
          <div className="row">
            <div className="col-md-7" id="aboutElectrical1">
              <p style={{ fontSize: "25px", fontFamily: "-moz-initial", fontWeight: "bolder" }}>Zaxix Electrical Capex is your premier partner for electrical capital expenditure (CAPEX) solutions. We specialize in providing comprehensive electrical infrastructure solutions tailored to meet the diverse needs of businesses and industries. With a focus on efficiency, reliability, and innovation, we are committed to delivering high-quality electrical solutions that drive productivity, reduce costs, and enhance safety. At Zaxix Electrical Capex, our mission is to empower businesses with the electrical infrastructure they need to succeed in today's competitive marketplace. We strive to be the go-to provider for all your electrical capital expenditure needs, offering expert guidance, reliable products, and exceptional service every step of the way.</p>
            </div>
            <div className="col-md-5" id='aboutElectrical2'>
              <div className="row">
                <div className="col-md-6 my-4">
                    <i className="bi bi-nintendo-switch" style={{ fontSize: '4rem' }}></i>
                    <h5>Electrical System Design</h5>
                </div>
                <div className="col-md-6 my-4">
                    <i className="bi bi-cart4" style={{ fontSize: '4rem' }}></i>
                    <h5>Procurement</h5>
                </div>
                <div className="col-md-6 my-4">
                    <i className="bi bi-gear" style={{ fontSize: '4rem' }}></i>
                    <h5>Installation</h5>
                </div>
                <div className="col-md-6 my-4">
                    <i className="bi bi-tools" style={{ fontSize: '4rem' }}></i>
                    <h5>Maintenance & Support</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className='aboutElectrical' id='aboutElectricals'>
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>ABOUT ZAXIX ELECTRICAL CAPEX</b></h2>
            <div className="text-muted">
              Zaxix Electrical Capex is your premier partner for electrical capital expenditure (CAPEX) solutions. We specialize in providing comprehensive electrical infrastructure solutions tailored to meet the diverse needs of businesses and industries. With a focus on efficiency, reliability, and innovation, we are committed to delivering high-quality electrical solutions that drive productivity, reduce costs, and enhance safety. At Zaxix Electrical Capex, our mission is to empower businesses with the electrical infrastructure they need to succeed in today's competitive marketplace. We strive to be the go-to provider for all your electrical capital expenditure needs, offering expert guidance, reliable products, and exceptional service every step of the way.
            </div>
        </section> */}

        {/* <section className='electricalServices' id='electricalService'>
          <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SERVICES</b></h2>
          <div className="row">
            <div className="col-md-3">
                <i className="bi bi-nintendo-switch" style={{ fontSize: '4rem' }}></i>
                <p className='text-muted'>Electrical System Design</p>
            </div>
            <div className="col-md-3">
                <i className="bi bi-cart4" style={{ fontSize: '4rem' }}></i>
                <p className='text-muted'>Procurement</p>
            </div>
            <div className="col-md-3">
                <i className="bi bi-gear" style={{ fontSize: '4rem' }}></i>
                <p className='text-muted'>Installation</p>
            </div>
            <div className="col-md-3">
                <i className="bi bi-tools" style={{ fontSize: '4rem' }}></i>
                <p className='text-muted'>Maintenance & Support</p>
            </div>
          </div>
        </section> */}

        {/* <section className="chooseElectrical">
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Why Choose US?</b></h2>
          <div className="accordion my-4" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Expertise
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  With years of experience in the electrical industry, our team of experts brings a wealth of knowledge and expertise to every project. From initial consultation to project completion, you can trust us to deliver solutions that meet your unique requirements and exceed your expectations.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Comprehensive Solutions
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  Whether you're upgrading existing electrical systems, expanding your facilities, or starting from scratch, we offer comprehensive solutions to meet your electrical CAPEX needs. From design and engineering to procurement and installation, we've got you covered.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Quality Assurance
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  We understand the importance of reliability and performance when it comes to electrical infrastructure. That's why we source only the highest quality products from trusted manufacturers to ensure the durability and longevity of your electrical systems.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Cost-Effective Solutions
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  We know that managing costs is essential for any business. That's why we strive to deliver cost-effective solutions that provide maximum value for your investment. From optimizing energy efficiency to streamlining installation processes, we're committed to helping you achieve your goals within budget.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Customer Satisfaction
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body text-muted">
                  At Zaxix Electrical Capex, customer satisfaction is our top priority. We pride ourselves on delivering exceptional service and support to our clients, ensuring that their needs are met and their expectations are exceeded at every stage of the project.
                </div>
              </div>
            </div>
          </div>
        </section> */}
      
      <section className='electricalProduct'>
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR Electrical Panel Segments</b></h2>
        <div className="container my-4" align="center">                
          <div className="row flex-nowrap overflow-hidden" id="scrollableRow">
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product1} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>        
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product2} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>                
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product3} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product4} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>        
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "13rem" }}>
                <img src={product5} className="card-img-top" alt="..." style={{ height: "13rem" }} />
                <div className="card-body">
                </div>
              </div>                
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product6} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product7} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>        
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product8} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>                
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "13rem" }}>
                <img src={product9} className="card-img-top" alt="..." style={{ height: "13rem" }} />
                <div className="card-body">
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "18rem" }}>
                <img src={product10} className="card-img-top" alt="..." />
                <div className="card-body">
                </div>
              </div>        
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card my-2" style={{ width: "14rem" }}>
                <img src={product13} className="card-img-top" alt="..." style={{ height: "18rem" }} />
                <div className="card-body">
                </div>
              </div>        
            </div>
          </div>
          <div className='buttons-container'>
            <span className='my-2 mx-4 btn-scroll' onClick={scrollLeft}><i className="bi bi-arrow-left-circle" style={{ fontSize: "3em" }}></i></span>
            <span className='my-2 mx-4 btn-scroll' onClick={scrollRight}><i className="bi bi-arrow-right-circle" style={{ fontSize: "3em" }}></i></span>
          </div>
        </div>
      </section>

      <section className="ourGroupWebsites" style={{ position: "relative" }}>
        <video autoPlay playsInline controls={false} loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "1" }}>
            <source src={groupWebsites} type="video/mp4" />
            {/* Add more <source> elements for other video formats if needed */}
            Your browser does not support the video tag.
        </video>
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR OTHER SEGMENTS</b></h2>
        <div className="container">
          <div className="row">
            <div className="mx-4 my-4 text-muted" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <div className="service-item">
                <Link to="/electronics" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage1} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRONICS</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/solar" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                <img src={serviceImage2} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                <p style={{ textAlign: "center" }}><b>ZAXIX SOLAR</b></p>
              </div></Link>
              </div>
              <div className="service-item">
                <Link to="/wind" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage3} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX WIND</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/electrical" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage4} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRICAL CAPEX</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/technologies" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage5} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX TECHNOLOGIES</b></p>
                </div></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Electrical