import React from 'react'
import technologies from '../assets/technologies.mp4'
import { Link } from 'react-router-dom';
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'
import groupWebsites from '../assets/services/6.mp4'
import animation1 from '../assets/technologiesAnimation/1.mp4'
import animation3 from '../assets/technologiesAnimation/3.mp4'
import animation4 from '../assets/technologiesAnimation/4.mp4'

const Technologies = () => {
  document.addEventListener("DOMContentLoaded", function() {
    // Get the video element
    var video = document.querySelector('.technologiesTag');
  
    // Play the video
    video.play();
  });

  return (
    <div className='container-fluid'>
      <section className='technologies-background'>
        <video className="technologiesTag" autoPlay loop muted playsInline>
          <source src={technologies} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <div className="text-overlay frame-1">
            <p className="text-uppercase title text-light"><b>Welcome To <span style={{ color: "yellow" }}>Zaxix Technologies</span></b></p>
            <p className="sub-title text-light">Your IT Problems, Our Expert Solutions!</p>
          </div>
        </div>
      </section>
<hr />
      <section className='technologiesServices' id='technologiesService' style={{ position: "relative" }}>
        {/* <video autoPlay loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "0.3" }}> */}
            {/* <source src={animation4} type="video/mp4" /> */}
            {/* Add more <source> elements for other video formats if needed */}
            {/* Your browser does not support the video tag. */}
        {/* </video> */}
          <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SERVICES</b></h2>
          <div className="row text-dark">
            <div className="col-md-4 my-4">
                <i className="bi bi-code-slash" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Software Development</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-telephone-plus" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>IT Consulting</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-search" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Digital Marketing</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-shield-lock" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Cybersecurity</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-code-square" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Web Design</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-list-check" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Web Hosting</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-cpu" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Automation</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-building-gear" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>Business Solutions</h5>
            </div>
            <div className="col-md-4 my-4">
                <i className="bi bi-whatsapp" style={{ fontSize: '4rem' }}></i>
                <h5 className=''>WhatsApp Messaging Services</h5>
            </div>
          </div>
      </section>
      {/* <hr /> */}
      <section className="aboutTechnologies" id='aboutTechnologie' style={{ position: "relative" }}>
        <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>ABOUT ZAXIX TECHNOLOGIES</b></h2>
        <div className="row">
          <div className="col-md-6">
            <video src={animation1} autoPlay playsInline controls={false} loop muted style={{ height: "30vh", width: "30vh" }}></video>
          </div>
          <div className="col-md-6">
            <div className="text-muted">
              <b>Zaxix Technologies is a dynamic leader in the realm of digital innovation, dedicated to empowering businesses and individuals with transformative technology solutions. With a focus on creativity, reliability, and customer-centricity, we strive to be your trusted partner in navigating the complexities of the digital landscape and unlocking new opportunities for growth and success. At Zaxix Technologies, our vision is to harness the power of technology to drive positive change and shape a better future for all. We envision a world where innovative technologies empower individuals, businesses, and societies to thrive in an increasingly connected and digital world. Through our commitment to innovation, excellence, and ethical business practices, we aim to be at the forefront of this digital revolution, driving progress and creating value for our customers and stakeholders.</b>
            </div>
          </div>
        </div>
        {/* <div className="text-light">
          Zaxix Technologies is a dynamic leader in the realm of digital innovation, dedicated to empowering businesses and individuals with transformative technology solutions. With a focus on creativity, reliability, and customer-centricity, we strive to be your trusted partner in navigating the complexities of the digital landscape and unlocking new opportunities for growth and success. At Zaxix Technologies, our vision is to harness the power of technology to drive positive change and shape a better future for all. We envision a world where innovative technologies empower individuals, businesses, and societies to thrive in an increasingly connected and digital world. Through our commitment to innovation, excellence, and ethical business practices, we aim to be at the forefront of this digital revolution, driving progress and creating value for our customers and stakeholders.
        </div> */}
      </section>

      <section className="technologiesProjects">
      <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b>OUR PROJECTS</b></h2>
      <div className="container my-4">
        <div className="row">
          {/* <div className="mx-4 my-4" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}> */}
            <div className="col-md-4 my-4">
              <div style={{ borderRadius: "50%", background: "#0e0e0e", boxShadow: "5px -5px 5px #5a5a5a, -5px 5px 5px #ffffff", color: "white", width: "auto", minHeight: "7rem", textAlign: "center" }}>
                <p style={{ paddingTop: "40px" }}><b>Portfolio Website</b></p>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div style={{ borderRadius: "50%", background: "#0e0e0e", boxShadow: "5px -5px 5px #5a5a5a, -5px 5px 5px #ffffff", color: "white", width: "auto", minHeight: "7rem", textAlign: "center" }}>
                <p style={{ paddingTop: "40px" }}><b>Donation Management System</b></p>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div style={{ borderRadius: "50%", background: "#0e0e0e", boxShadow: "5px -5px 5px #5a5a5a, -5px 5px 5px #ffffff", color: "white", width: "auto", minHeight: "7rem", textAlign: "center" }}>
                <p style={{ paddingTop: "40px" }}><b>Hotel Management System</b></p>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div style={{ borderRadius: "50%", background: "#0e0e0e", boxShadow: "5px -5px 5px #5a5a5a, -5px 5px 5px #ffffff", color: "white", width: "auto", minHeight: "7rem", textAlign: "center" }}>
                <p style={{ paddingTop: "40px" }}><b>Jobs Portal</b></p>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div style={{ borderRadius: "50%", background: "#0e0e0e", boxShadow: "5px -5px 5px #5a5a5a, -5px 5px 5px #ffffff", color: "white", width: "auto", minHeight: "7rem", textAlign: "center" }}>
                <p style={{ paddingTop: "40px" }}><b>E-Commerce Website</b></p>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div style={{ borderRadius: "50%", background: "#0e0e0e", boxShadow: "5px -5px 5px #5a5a5a, -5px 5px 5px #ffffff", color: "white", width: "auto", minHeight: "7rem", textAlign: "center" }}>
                <p style={{ paddingTop: "40px" }}><b>WhatsApp Broadcasting Message System</b></p>
              </div>
            </div>
          {/* </div> */}
        </div>
        <Link to="/technologiesProject" className='text-muted'><h5 className='text-center'>Click here for Project Gallery...</h5></Link>
      </div>
      </section>

      <section className="ourGroupWebsites" style={{ position: "relative" }}>
        <video autoPlay playsInline controls={false} loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "0.9" }}>
            <source src={groupWebsites} type="video/mp4" />
            {/* Add more <source> elements for other video formats if needed */}
            Your browser does not support the video tag.
        </video>
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR OTHER SEGMENTS</b></h2>
        <div className="container">
          <div className="row">
            <div className="mx-4 my-4" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <div className="service-item">
                <Link to="/electronics"><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage1} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p className="text-muted" style={{ textAlign: "center" }}><b>ZAXIX ELECTRONICS</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/solar"><div style={{ display: "inline-block", marginRight: "20px" }}>
                <img src={serviceImage2} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                <p className="text-muted" style={{ textAlign: "center" }}><b>ZAXIX SOLAR</b></p>
              </div></Link>
              </div>
              <div className="service-item">
                <Link to="/wind"><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage3} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p className="text-muted" style={{ textAlign: "center" }}><b>ZAXIX WIND</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/electrical"><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage4} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p className="text-muted" style={{ textAlign: "center" }}><b>ZAXIX ELECTRICAL CAPEX</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/technologies"><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage5} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p className="text-muted" style={{ textAlign: "center" }}><b>ZAXIX TECHNOLOGIES</b></p>
                </div></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Technologies