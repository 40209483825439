import React from 'react'
import { Link } from 'react-router-dom'
import Project1 from '../assets/solarProjects/1/1.jpg'
import Project2 from '../assets/solarProjects/1/2.jpg'
import Project3 from '../assets/solarProjects/1/3.jpg'
import Project4 from '../assets/solarProjects/1/6.jpg'

const SolarProject = () => {
  return (
    <div>
        <section className="solarProject1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>Project Gallery</strong></h1>
        </section>

        <section className="solarProject2">
        <h2 className="section-heading text-uppercase my-4" style={{ color: "#01013d" }}><b><u>Our Highlights</u></b></h2>
        <div>
          <div className="row justify-content-center mx-2 my-4">
            <div className="col-md-6">
              <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={Project1} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={Project2} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={Project3} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <h5 className='mt-2'>Commercial "16 KW"</h5>
              <p className='mt-2'>Balod, Chhattisgarh</p>
            </div>
            <div className="col-md-6">
              <div id="carouselExample1" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={Project4} class="d-block w-100" alt="..." />
                  </div>
                </div>
              </div>
              <h5 className='mt-2'>IOCL, Petrol Station, "3 KW"</h5>
              <p className='mt-2'>Bhilai, Chhattisgarh</p>
            </div>
          </div>
        </div>
        </section>
    </div>
  )
}

export default SolarProject