import React from 'react'

const WindProject = () => {
  return (
    <div>
        <section className="electronicProject1">
            <h1 style={{textAlign: "left", marginTop: "20vh"}}><strong>Project Gallery</strong></h1>
        </section>
    </div>
  )
}

export default WindProject