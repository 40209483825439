import React from 'react'

const Careers = () => {
  return (
    <div>
        <section className='career-background'>
            <div className="overlay">
              <div className="text-overlay frame-1">
                <p className="text-uppercase title"><b>JOIN THE <span style={{ color: "yellow" }}>ZAXIX GROUP</span></b></p>
                <p className="sub-title"></p>
              </div>
            </div>
        </section>

        <section className='workZaxix'>
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Why Work at Zaxix Group?</b></h2>
            <div className="text-muted">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Meaningful Work
                        </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            At Zaxix Group, your work will have a meaningful impact on the world. Whether you're developing renewable energy solutions, creating innovative technology products, or promoting sustainability initiatives, you'll be contributing to a brighter, more sustainable future for generations to come.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Collaborative Environment
                        </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Join a team of passionate professionals who are committed to collaboration, teamwork, and mutual support. At Zaxix Group, we value diverse perspectives and encourage open communication, creativity, and innovation.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Career Growth Opportunities
                        </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            We believe in investing in the professional growth and development of our employees. With opportunities for learning, training, and advancement, Zaxix Group is the perfect place to take your career to the next level.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Work-Life Balance
                        </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            We understand the importance of maintaining a healthy work-life balance. At Zaxix Group, we offer flexible work arrangements, competitive benefits, and a supportive environment that prioritizes employee well-being and personal fulfillment.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="current-openings">
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Current Openings</b></h2>
            <div className="text-muted">
                <ul style={{ textAlign: 'left', fontSize: '18px' }}>
                    <li className='my-4'><strong>Software Engineer:</strong> Join our technology division and develop cutting-edge software solutions that drive innovation and impact.</li>
                    <li className='my-4'><strong>Renewable Energy Specialist:</strong> Help us lead the transition towards a cleaner, greener energy future as part of our renewable energy team.</li>
                    <li className='my-4'><strong>Finance Analyst:</strong> Join our operations division and play a key role in managing financial operations and supporting business growth.</li>
                    <li className='my-4'><strong>Marketing Coordinator:</strong> Help us spread the word about our mission and products as part of our marketing team.</li>
                </ul>
            </div>
        </section>

        <section className="apply">
            <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>How to Apply?</b></h2>
            <div className="text-muted">
                Ready to join the Zaxix Group team? To apply for any of our current openings, please submit your resume and cover letter to <b>"careers@zaxix.in"</b>. Be sure to include the job title in the subject line of your email. We look forward to hearing from you!
            </div>
            <h6 className="section-heading text-uppercase text-muted mt-4"><b>Don't See The Right Opportunity</b></h6>
            <div className="text-muted">
                Even if you don't see a current opening that matches your skills and experience, we're always looking for talented individuals to join our team. Feel free to submit your resume and cover letter to <b>"careers@zaxix.in"</b>, and we'll keep it on file for future opportunities.
            </div>
        </section>

        <section className='joinUs'>
            <h5 style={{ color: "#01013d" }}>
            <strong>Join us at Zaxix Group and be a part of a team that is making a difference in the world. Together, we can build a brighter, more sustainable future for generations to come. Apply today and embark on a rewarding career with Zaxix Group!</strong>
            </h5>
        </section>
    </div>
  )
}

export default Careers