import React from 'react'
import ZAxix from '../assets/white-192x192.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
        <section className="footer pb-4 mt-4">
        <div className="container">
          <footer className="text-center text-md-start text-white">
            <div className="container p-4 pb-0">
              <section className="">
                <div className="row">
                  <div className="col-md-12 col-lg-4 col-xl-4 mx-auto mt-3 text-center">
                    {/* <h6 className="text-uppercase mb-4 font-weight-bold">
                      ZAXIX
                    </h6>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit.
                    </p> */}
                    <img src={ZAxix} alt="..." />
                  </div>
                  <hr className="w-100 clearfix d-md-none" />
                  {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3 ">
                    <h6 className="text-uppercase mb-4 font-weight-bold underline">Why Us</h6>
                    <p>
                      <a href="#">QUALITY SERVICES</a>
                    </p>
                    <p>
                      <a href="#">EXPERT WORKERS</a>
                    </p>
                    <p>
                      <a href="#">FREE CONSULTATION</a>
                    </p>
                    <p>
                      <a href="#">CUSTOMER SUPPORT</a>
                    </p>
                  </div> */}
                  <hr className="w-100 clearfix d-md-none" />
                  <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                    <h6 className="text-uppercase mb-4 font-weight-bold underline">
                      Our Segments
                    </h6>
                    <p>
                      <Link to="/electronics">ZAXIX ELECTRONICS</Link>
                    </p>
                    <p>
                      <Link to="/solar">ZAXIX SOLAR</Link>
                    </p>
                    <p>
                      <Link to="/wind">ZAXIX WIND</Link>
                    </p>
                    <p>
                      <Link to="/electrical">ZAXIX ELECTRICAL CAPEX</Link>
                    </p>
                    <p>
                      <Link to="/technologies">ZAXIX TECHNOLOGIES</Link>
                    </p>
                  </div>
                  <hr className="w-100 clearfix d-md-none" />
                  <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                    <h6 className="text-uppercase mb-4 font-weight-bold underline">
                      Useful links
                    </h6>
                    <p>
                      <a href="#">NEWSROOM</a>
                    </p>
                    <p>
                      <Link to="/careers">CAREERS</Link>
                    </p>
                    <p>
                      <a href="#">CONTACT US</a>
                    </p>
                    <p>
                      <a href="#">BLOGS</a>
                    </p>
                  </div>
                  <hr className="w-100 clearfix d-md-none" />
                  <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                    <h6 className="text-uppercase mb-4 font-weight-bold underline">Contact</h6>
                    <p>
                      <i className="fas fa-phone mr-3"></i>
                      <p>+91 8269209206</p>
                      <p>+91 8349702904</p>
                    </p>
                    <p>
                      <i className="fas fa-envelope mr-3"></i>
                      <p>productszx@gmail.com</p>
                    </p>
                    <p><i className="fas fa-home mr-3"></i>2nd Floor, 80-B, Shastri Market, Power House, Bhilai, C.G. 490026</p>
                  </div>
                </div>
              </section>
              <section className="">
                <div className="row mt-5">
                  <div className="col-md-2 col-lg-2 col-xl-2 mt-2">
                    <p>
                      <small><a href="#">Terms & Conditions</a></small>
                    </p>
                  </div>
                  {/* <div className="col-md-2 col-lg-2 col-xl-2 mt-2">
                    <p>
                      <a href="#">Modern Slavery</a>
                    </p>
                  </div> */}
                  <div className="col-md-2 col-lg-2 col-xl-2 mt-2">
                    <p>
                      <a href="#">Cookie Policy</a>
                    </p>
                  </div>
                  <div className="col-md-2 col-lg-2 col-xl-2 mt-2">
                    <p>
                      <a href="#">Privacy Policy</a>
                    </p>
                  </div>
                </div>
              </section>
              <hr className="my-3" />
              <section>
                
                <div className="row d-flex align-items-center">
                  <div className="col-md-7 col-lg-8 text-center text-md-start">
                      <p><small>© Copyright 2024 Zaxix Group</small></p>
                  </div>
                  <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                    {/* <a className="btn btn-outline-light btn-floating m-1 text-white" role="button"><i className="bi bi-facebook"></i></a> */}
                    {/* <a className="btn btn-outline-light btn-floating m-1 text-white" role="button"><i className="bi bi-twitter-x"></i></a> */}
                    <Link className="btn btn-outline-light btn-floating m-1 text-white" role="button" to="https://www.linkedin.com/company/zaxix-group/about/" target='_blank'><i className="bi bi-linkedin"></i></Link>
                    <Link className="btn btn-outline-light btn-floating m-1 text-white" role="button" to="https://www.instagram.com/zaxix_technologies?igsh=dTlqcnQzcm5leXFl&utm_source=qr " target='_blank'><i className="bi bi-instagram"></i></Link>
                  </div>
                </div>
              </section>
              <p className='text-center'>Designed And Developed By Zaxix Technologies</p>
            </div>
          </footer>
        </div>
        <section></section>
      </section>
    </div>
  )
}

export default Footer