import React, { useEffect, useState } from 'react'
import sample from '../assets/sample.mp4'
import aboutImage from '../assets/about/2.jpeg'
import serviceImage1 from '../assets/services/1.jpeg'
import serviceImage2 from '../assets/services/2.jpg'
import serviceImage3 from '../assets/services/3.jpg'
import serviceImage4 from '../assets/services/4.jpeg'
import serviceImage5 from '../assets/services/5.jpg'
import client1 from '../assets/client/aarogyam.jpg'
import client2 from '../assets/client/abis.png'
import client3 from '../assets/client/avinash.png'
import client4 from '../assets/client/ib.png'
import client5 from '../assets/client/blueEarth.png'
import client6 from '../assets/client/GoldenArm_India.png'
import client7 from '../assets/client/URC.png'
import client8 from '../assets/client/shrem.png'
import client9 from '../assets/client/HotelCentralPark.png'
import { Link } from 'react-router-dom'
import businessVideo from '../assets/services/7.mp4'
import groupWebsites from '../assets/services/6.mp4'

const Home = () => {
    document.addEventListener("DOMContentLoaded", function() {
        // Get the video element
        var video = document.querySelector('.videoTag');
      
        // Play the video
        video.play();
    });

    useEffect(() => {
        const cards = document.querySelectorAll('.card');

        cards.forEach(card => {
            card.addEventListener('mouseenter', () => {
                cards.forEach(c => {
                    if (c !== card) {
                        c.style.display = 'none'; // Hide other cards
                    }
                });
            });

            card.addEventListener('mouseleave', () => {
                cards.forEach(c => {
                    c.style.display = 'block'; // Show all cards when mouse leaves
                });
            });
        });
    }, []);

    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const texts = [
        { title: "Welcome To ", subtitle: "Zaxix Group!" },
        { title: "Let's power up with ", subtitle: "Zaxix Electronics!" },
        { title: "You are entering the world of ", subtitle: "AI!" },
        { title: "Mark the line to feel the guest of wind with ", subtitle: "Zaxix Wind!" },
        { title: "Become the tech ready with ", subtitle: "Zaxix Technologies!" },
        { title: "Green Energy, ", subtitle: "Go Solar!" },
        { title: "Become the part of ", subtitle: "sustainable environment..." },
        // Add more text objects as needed
    ];

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 3000); // Change the interval duration as needed (in milliseconds)

        return () => clearInterval(interval);
    }, [texts.length]);

  return (
    <div className='container-fluid'>

      <section className="video-background">
        <video className='videoTag' autoPlay loop muted playsInline>
          <source src={sample} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
            <div className="text-overlay frame-1">
            {/* <p className="text-uppercase title text-light"><b>Welcome To <span style={{ color: "Yellow" }}>Zaxix Group</span></b></p> */}
            {/* <p className="sub-title text-light">Where Innovation Meets Excellence!</p> */}
            <div className="sub-title-container">
            {texts.map((text, index) => (
              <p
                key={index}
                className={`text-uppercase titles text-light ${index === currentTextIndex ? 'visible' : ''}`}
              >
                <b>{text.title} <span style={{ color: "Yellow" }}>{text.subtitle}</span></b>
              </p>
            ))}
            </div>
            </div>
        </div>
      </section>

      <section className="values">
        <div className="row">
            <div className="col-md-3">
                <i className="bi bi-buildings-fill" style={{ fontSize: '4rem' }}></i>
                <h2>5+</h2>
                <p className='text-muted'>5 Years Of Experience</p>
            </div>
            <div className="col-md-3">
                <i className="bi bi-people-fill" style={{ fontSize: '4rem' }}></i>
                <h2>2500+</h2>
                <p className='text-muted'>Happy Customers</p>
            </div>
            <div className="col-md-3">
                <i className="bi bi-check-square-fill" style={{ fontSize: '4rem' }}></i>
                <h2>10+</h2>
                <p className='text-muted'>Project Done</p>
            </div>
            <div className="col-md-3">
                <i className="bi bi-person-fill-check" style={{ fontSize: '4rem' }}></i>
                <h2>100+</h2>
                <p className='text-muted'>Expert Workers</p>
            </div>
        </div>
      </section>

      <section className="about-section">
        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>ABOUT US</b></h2>
        {/* <h5 className='text-dark my-4'><b>Welcome to Zaxix Groups, where Innovation meets excellence. </b></h5> */}
        <div className="row my-4">
          <div className="col-md-6">
            <img src={aboutImage} alt="" className='aboutImage' />
          </div>
          <div className="col-md-6 text-dark" style={{textAlign: "left", fontSize: "18px", marginTop: "10vh"}}>
            <b>- Zaxix Electronics</b>
            <br /><b>- Zaxix Solar</b>
            <br /><b>- Zaxix Wind</b>
            <br /><b>- Zaxix Electrical Capex</b>
            <br /><b>- Zaxix Technologies</b>
            <br /><br />At <b>Zaxix Groups</b>, we are committed to driving positive change and revolutionizing industries through cutting-edge technology and sustainable solutions. With a focus on innovation, quality, and customer satisfaction, we strive to deliver products and services that exceed expectations and empower our clients to thrive in a rapidly evolving world. We are dedicated to pushing the boundaries of innovation and sustainability to create a brighter future for generations to come. Join us on our journey as we continue to shape the world through excellence in technology and a commitment to making a positive impact on the planet.
            </div>
        </div>
      </section>

      {/* <section className="page-section" id="ourServices"> 
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR SERVICES</b></h2>
                    <h3 className="section-subheading text-muted"></h3>
                </div>
                <ul className="timeline">
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={serviceImage1} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4><b>ZAXIX ELECTRONICS</b></h4>
                                <h4 className="subheading">Our Humble Beginnings</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">At <b>Zaxix Electronics</b>, we specialize in designing and manufacturing cutting-edge electronic products and components for a wide range of industries. From consumer electronics and telecommunications devices to industrial automation systems and IoT solutions, our innovative products are engineered to deliver superior performance, reliability, and versatility in the digital age.</p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={serviceImage2} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4><b>ZAXIX SOLAR</b></h4>
                                <h4 className="subheading">An Agency is Born</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">Harnessing the power of the sun, <b>Zaxix Solar</b> is dedicated to providing innovative solar energy solutions for residential, commercial, and industrial applications. Our high-efficiency solar panels, advanced inverters, and comprehensive energy management systems enable our clients to maximize their energy production, reduce their carbon footprint, and achieve energy independence.</p></div>
                        </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={serviceImage3} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4><b>ZAXIX WIND</b></h4>
                                <h4 className="subheading">Our Humble Beginnings</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted"><b>Zaxix Wind</b> is at the forefront of the renewable energy revolution, offering innovative wind energy solutions to power the future sustainably. With state-of-the-art wind turbines, advanced monitoring and control systems, and comprehensive wind farm development services, we are driving the transition to clean, renewable energy sources and paving the way for a greener tomorrow.</p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={serviceImage4} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4><b>ZAXIX ELECTRICAL CAPEX</b></h4>
                                <h4 className="subheading">An Agency is Born</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">As a leader in electrical infrastructure solutions, <b>Zaxix Electrical Capex</b> offers a comprehensive range of products and services to meet the diverse needs of our clients. From power distribution and transmission equipment to renewable energy solutions and smart grid technologies, we provide tailored solutions that enable efficient, reliable, and sustainable electrical systems.</p></div>
                        </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={serviceImage5} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4><b>ZAXIX TECHNOLOGIES</b></h4>
                                <h4 className="subheading">An Agency is Born</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted"><b>Zaxix Technologies</b> is a dynamic leader in the realm of digital innovation, dedicated to empowering businesses and individuals with transformative technology solutions. With a focus on creativity, reliability, and customer-centricity, we strive to be your trusted partner in navigating the complexities of the digital landscape and unlocking new opportunities for growth and success.</p></div>
                        </div>
                    </li>
                </ul>
            </div>
      </section> */}

        <section className="page-section" id="ourServices"> 
            <div className="container-fluid video-container">
                <video src={businessVideo} autoPlay loop muted playsInline className='background-video'></video>
                <div className="text-center">
                    <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR BUSINESSES</b></h2>
                    <h3 className="section-subheading text-muted"></h3>
                </div>
                <div className="row">
                    <div className='card-container'>
                    <div className="col-md-2 mx-3" style={{ display: "inline-block" }}>
                    <div className="card text-muted">
                        <Link to="/electronics"><img src={serviceImage1} className="card-img-top" alt="..." style={{ height: "9rem" }}/></Link>
                        <div className="card-body">
                            <h5 className="card-title"><b>Zaxix Electronics</b></h5>
                            <p className="card-text">At <b>Zaxix Electronics</b>, we specialize in designing and manufacturing cutting-edge electronic products and <Link to="/electronics">Read More...</Link></p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2 mx-3" style={{ display: "inline-block" }}>
                    <div className="card text-muted">
                        <Link to="/solar"><img src={serviceImage2} className="card-img-top" alt="..." style={{ height: "9rem" }}/></Link>
                        <div className="card-body">
                            <h5 className="card-title"><b>Zaxix Solar</b></h5>
                            <p className="card-text">Harnessing the power of the sun, <b>Zaxix Solar</b> is dedicated to providing innovative solar energy solutions for <Link to="/solar">Read More...</Link></p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2 mx-3" style={{ display: "inline-block" }}>
                    <div className="card text-muted">
                        <Link to="/wind"><img src={serviceImage3} className="card-img-top" alt="..." style={{ height: "9rem" }}/></Link>
                        <div className="card-body">
                            <h5 className="card-title"><b>Zaxix Wind</b></h5>
                            <p className="card-text"><b>Zaxix Wind</b> is at the forefront of the renewable energy revolution, offering innovative wind energy solutions <Link to="/wind">Read More...</Link></p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2 mx-3" style={{ display: "inline-block" }}>
                    <div className="card text-muted">
                        <Link to="/electrical"><img src={serviceImage4} className="card-img-top" alt="..." style={{ height: "9rem" }}/></Link>
                        <div className="card-body">
                            <h5 className="card-title"><b>Zaxix Electrical Capex</b></h5>
                            <p className="card-text">As a leader in electrical infrastructure solutions, <b>Zaxix Electrical Capex</b> offers a comprehensive <Link to="/electrical">Read More...</Link></p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2 mx-3" style={{ display: "inline-block" }}>
                    <div className="card text-muted">
                        <Link to="/technologies"><img src={serviceImage5} className="card-img-top" alt="..." style={{ height: "9rem" }}/></Link>
                        <div className="card-body">
                            <h5 className="card-title"><b>Zaxix Technologies</b></h5>
                            <p className="card-text"><b>Zaxix Technologies</b> is a dynamic leader in the realm of digital innovation, dedicated <Link to="/technologies">Read More...</Link></p>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>

      <section className="clients-section">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>Our Prestigious Connect</b></h2>
                <h3 className="section-subheading text-muted"></h3>
            </div>
            <div className="scroll-wrapper">
            <div className="clients-scroll-container mx-4 my-4">
                <img src={client1} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client2} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client3} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client4} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client5} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client6} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client7} alt="..." style={{ height: "200px", width: "300px", padding: "20px" }}/>
                <img src={client8} alt="..." style={{ height: "200px", width: "300px", padding: "20px" }}/>
                <img src={client9} alt="..." style={{ height: "200px", width: "300px", padding: "20px" }}/>
                {/* Repeating the same images */}
                <img src={client1} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client2} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client3} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client4} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client5} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client6} alt="..." style={{ height: "200px", width: "200px", padding: "20px" }}/>
                <img src={client7} alt="..." style={{ height: "200px", width: "300px", padding: "20px" }}/>
                <img src={client8} alt="..." style={{ height: "200px", width: "300px", padding: "20px" }}/>
                <img src={client9} alt="..." style={{ height: "200px", width: "300px", padding: "20px" }}/>
            </div>
            </div>
        </div>
      </section>

      <section className="ourGroupWebsites" style={{ position: "relative" }}>
        <video autoPlay playsInline controls={false} loop muted style={{ position: "absolute", zIndex: "-1", width: "100%", height: "100%", objectFit: "cover", left: "0", top: "0", opacity: "1" }}>
            <source src={groupWebsites} type="video/mp4" />
            {/* Add more <source> elements for other video formats if needed */}
            Your browser does not support the video tag.
        </video>

        <h2 className="section-heading text-uppercase" style={{ color: "#01013d" }}><b>OUR OTHER SEGMENTS</b></h2>
        <div className="container">
          <div className="row">
            <div className="mx-4 my-4 text-muted" style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <div className="service-item">
                <Link to="/electronics" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage1} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRONICS</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/solar" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                <img src={serviceImage2} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                <p style={{ textAlign: "center" }}><b>ZAXIX SOLAR</b></p>
              </div></Link>
              </div>
              <div className="service-item">
                <Link to="/wind" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage3} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX WIND</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/electrical" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage4} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX ELECTRICAL CAPEX</b></p>
                </div></Link>
              </div>
              <div className="service-item">
                <Link to="/technologies" style={{ color: "GrayText" }}><div style={{ display: "inline-block", marginRight: "20px" }}>
                  <img src={serviceImage5} alt="..." style={{ height: "200px", width: "200px", padding: "20px", borderRadius: "60px" }}/>
                  <p style={{ textAlign: "center" }}><b>ZAXIX TECHNOLOGIES</b></p>
                </div></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home